$breakPoint-small: 375px;
$breakPoint-mobile: 768px;
$breakPoint-desktop: 1024px;
$breakPoint-noteBook: 1280px;
$breakPoint-wideDesktop: 1400px;

@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: $breakPoint-small) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (max-width: $breakPoint-mobile) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (max-width: $breakPoint-desktop) {
      @content;
    }
  } @else if $media == noteBook {
    @media only screen and (max-width: $breakPoint-noteBook) {
      @content;
    }
  } @else if $media == wideDesktop {
    @media only screen and (min-width: $breakPoint-wideDesktop) {
      @content;
    }
  }
}
