html,
body {
  padding: 0;
  margin: 0;
  // font-family: "Apple SD Gothic Neo", -apple-system, BlinkMacSystemFont,
  //   Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  //   Helvetica Neue, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-box-sizing: border-box;
  // box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
}
div {
  white-space: pre-wrap;
}
// @font-face {
//   font-family: "Apple SD Gothic Neo";
//   src: local("Apple SD Gothic Neo"), local("AppleSDGothicNeo-Regular"),
//     url("../assets/fonts/AppleSDGothicNeo-Regular.woff2") format("woff2"),
//     url("../assets/fonts/AppleSDGothicNeo-Regular.woff") format("woff"),
//     url("../assets/fonts/AppleSDGothicNeo-Regular.ttf") format("truetype")
//     url("../assets/fonts/AppleSDGothicNeo-Regular.svg") format("svg");
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: "Apple SD Gothic Neo";
//   src: local("Apple SD Gothic Neo"), local("AppleSDGothicNeo-Bold"),
//     url("../assets/fonts/AppleSDGothicNeo-Bold.woff2") format("woff2"),
//     url("../assets/fonts/AppleSDGothicNeo-Bold.woff") format("woff"),
//     url("../assets/fonts/AppleSDGothicNeo-Bold.ttf") format("truetype")
//     url("../assets/fonts/AppleSDGothicNeo-Bold.svg") format("svg");
//   font-weight: bold;
//   font-style: normal;
//   font-stretch: normal;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
