@import "./utils.scss";

.titleText {
  padding-top: 10px;
  padding-bottom: 25px;
  font-weight: 700;
  font-size: 23px;

  @include respond-to(mobile) {
    padding-top: 0px;
    padding-bottom: 15px;
    font-size: 17px;
  }
}

.ratingContainer {
  padding-bottom: 30px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  color: #868688;

  @include respond-to(mobile) {
    font-size: 14px;
  }
}

.alertMessage {
  margin: 5px;
  font-size: 13px;
  color: #f24147;
}

.submitMessage {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.7rem;

  @include respond-to(mobile) {
    font-size: 14px;
    line-height: 1.2rem;
  }
}

.formBtn {
  padding: 9px 40px;
  background-color: #fcd11e;
  border-radius: 21px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  &:active {
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }
}

form {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  border-radius: 1em;
}

textarea {
  width: 100%;
  height: 90px;
  // resize: none;
  padding: 10px;
  border: 1px solid #e4e4e6;
  border-radius: 10px;
  font-family: inherit;
  font-size: 15px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  align-items: center;
  justify-items: center;
}

.tooltip {
  width: 100%;
  color: #868688;
  font-size: 12px;
  text-align: right;
}

.checkboxwrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;

  .checkLabel {
    margin-top: 5px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #868688;
  }
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
}

// 체크박스 클릭하기 전
input[type="checkbox"] + label::before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  background-color: white;
  border: 1px solid #e4e4e6;
}

// 체크박스 클릭한 후
input[type="checkbox"]:checked + label::before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  background-color: #fcd11e;
  border: 1px solid #fcd11e;
}

// 체크박스 내부 체크 표시
input[type="checkbox"]:checked + label::after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
